const AddressMixin = {
    methods: {
        buildAddress(addressObj) {
            let addressString = '';
            if (addressObj) {
                addressString = addressObj.subpremise ?? '';
                if (addressObj.street) {
                    addressString = addressString === '' ? addressObj.street : `${addressString}, ${addressObj.street}`;
                }
                if (addressObj.suburb) {
                    addressString = addressString === '' ? addressObj.suburb : `${addressString}, ${addressObj.suburb}`;
                }
                if (addressObj.state) {
                    let state = addressObj.state.toUpperCase();
                    addressString = addressString === '' ? state : `${addressString} ${state}`;
                }
                if (addressObj.postcode) {
                    addressString = addressString === '' ? addressObj.postcode : `${addressString} ${addressObj.postcode}`;
                }
                if (addressObj.country) {
                    addressString = addressString === '' ? addressObj.country : `${addressString}, ${addressObj.country}`;
                }
            }
            return addressString;
        },
    },
};

export default AddressMixin;