<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Follow-up/List</h4>
          <!--          <div>
                      <router-link :to="can('post-create') ? {name:'appScamAlertCreate'} : '#'">
                        <span :title="can('post-create') ? 'Create' : 'Create Forbidden'"
                              class="glow d-flex align-items-center">
                          <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
                      </router-link>
                    </div>-->
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center">
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Show Per Page</label>
                <VueMultiselect v-model="selectedPagination" :options="paginationList" :close-on-select="true"
                                label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-3 pt-1">
                <div class="form-group">
                  <label>Reference</label>
                  <input v-model="getFollowUpsParams.reference" class="form-control " type="text"
                         placeholder="Enter Reference">
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Service</label>
                <VueMultiselect :allowEmpty="false" v-model="selectedService" class="" :options="serviceList"
                                :close-on-select="true"
                                placeholder="Select Service Name" label="name" track-by="name"
                                :show-labels="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Status</label>
                <VueMultiselect v-model="selectedStatus" class="" :options="statusList" :close-on-select="true"
                                placeholder="Select status" label="name" track-by="value" :show-labels="false"
                                :allow-empty="false"/>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 col-sm-12 col-md-9">
                <input v-model="getFollowUpsParams.title" class="form-control search-admin-input-element" type="text"
                       placeholder="Search By Title">
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyFollowUpFilter(null)">Filter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" style="width: 3%">SL</th>
                    <th class="position-relative" style="width: 17%">Reference</th>
                    <th class="position-relative" style="width: 30%">Message</th>
                    <th class="position-relative" style="width: 18%">Service Category</th>
                    <th class="position-relative" style="width: 5%">Created By</th>
                    <th class="position-relative" style="width: 15%">Date</th>
                    <th class="position-relative text-right" style="width: 12%">ACTIONS</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(followUp, index) in followUps" :key="index">
                    <td>
                      <span>{{ index + 1 }}</span>
                    </td>
                    <td>
                      <span title="Click to view " class="text-secondary text-bold-500 cursor-pointer"
                            @click="vieAppointmentFullDetailsHandler(followUp?.reference?.reference)">
                        #{{ followUp?.reference?.reference ?? '' }}</span> <br>
                      <span class="text-primary">{{ followUp?.reference?.customer?.user?.full_name }}</span> <br>
                      <span>{{ buildAddress(followUp?.reference?.customer.address) }}</span>

                    </td>
                    <td>
                      <span>{{ followUp.body }}</span>
                    </td>
                    <td>{{ getNamesFromIds(followUp?.service_ids).toString() }}</td>
                    <td>
                      <span>{{ followUp?.createdBy?.full_name }}</span>
                    </td>
                    <td>
                      <span>{{ followUp.calling_date }}</span>
                    </td>

                    <td>
                      <div class="d-flex align-items-top justify-content-around">
                        <!--                        <div>
                                                  <router-link
                                                      :to="can('follow-up-view') ? {name: 'appAppointmentCreate',params: { customerId: followUp?.rerefence?.customer?.id }} : '#'"
                                                      :title="can('follow-up-view') ? 'View' : 'View Forbidden'"
                                                      class="cursor-pointer view-edit-delete-icon"
                                                      :style="{ height: '18px' }">
                                                    <i class="bx bx-show"></i>
                                                  </router-link>
                                                </div>-->
                        <div class="dropdown py-0 px-0 view-edit-delete-icon" :style="{height:'18px'}">
                                                                          <span type="button" class="px-0 py-0"
                                                                                data-toggle="dropdown"
                                                                                aria-haspopup="true"
                                                                                aria-expanded="false">
                                                                              <i class='bx bx-dots-vertical-rounded h-100'></i>
                                                                          </span>
                          <div class="dropdown-menu" style="min-height: 100px">
                            <router-link
                                :to="{name:'appAppointmentCreate', params:{ customerId: followUp?.reference?.customer?.id }}"
                                :disabled="!can('appointment-create')"
                                :title="can('appointment-create') ? 'Appointment Create' : 'Create Forbidden'"
                                class="dropdown-item">Make Appointment
                            </router-link>
                            <router-link
                                :to="{name:'appOrderCreate', params:{ customerId: followUp?.reference?.customer?.id }}"
                                :disabled="!can('order-create')"
                                :title="can('order-create') ? 'Order Create' : 'Create Forbidden'"
                                class="dropdown-item">Order Create
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </td>

                  </tr>
                  </tbody>
                </table>
                <ListPagination position="right" :pagination-data="paginateLinks"
                                @getClickedPage="applyFollowUpFilter"/>
              </div>
            </div>
          </div>
        </div>

        <scam-status-update-alert-modal :model-for-updating="modelForUpdating" model-name="scam"
                                        modal-name="scamStatusUpdateAlertModal"
                                        @confirmModelUpdating="scamStatusUpdating"/>

        <BlogIsFeaturedUpdateAlertModal :model-for-updating="modelForUpdating" model-name="scam"
                                        modal-name="scamIsFeaturedUpdateAlertModal"
                                        @confirmModelUpdating="scamIsFeaturedUpdating"/>


        <BlogDeleteAlertModal modal-name="deleteBlogModal" model-name="scam" :model-id="modelIdForDelete"
                              @confirmModelDeletion="onConfirmModelDeletion"/>

      </section>
    </template>
  </AppLayout>
</template>

<script>
//component
import AppLayout from '@/layouts/backEnd/AppLayout';
import ListPagination from '@/components/backEnd/pagination/ListPagination';
import ScamStatusUpdateAlertModal from '@/components/backEnd/modal/UpdateAlertModal';
import BlogIsFeaturedUpdateAlertModal from '@/components/backEnd/modal/UpdateAlertModal';

// mixin
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

// core package
import {
  mapActions,
  mapGetters
} from "vuex";

// package
import VueMultiselect from 'vue-multiselect';
import BlogDeleteAlertModal from "@/components/backEnd/modal/DeleteAlertModal";
import AddressMixin from "@/components/backEnd/mixins/AddressMixin";

export default {
  name: "FollowUpList.vue",
  mixins: [ShowToastMessage, Loader, Authorization, AddressMixin],
  components: {
    AppLayout,
    ScamStatusUpdateAlertModal,
    BlogIsFeaturedUpdateAlertModal,
    BlogDeleteAlertModal,
    ListPagination,
    VueMultiselect
  },
  data() {
    return {
      scam: {},
      unauthorized: 'You are unauthorized to perform this action.',
      viewBlogObj: {},
      selectedPagination: {
        value: '',
        name: 'Default'
      },
      selectedIsFeatured: {
        value: '',
        name: 'Any'
      },
      selectedStatus: {
        value: '',
        name: 'Any'
      },
      selectedService: {
        value: '',
        name: 'Any'
      },
      getFollowUpsParams: {
        reference: '',
        status: '',
        paginate: 1,
        order_by_id: 'DESC',
        pagination: '',
        page: '',
        with_relation: ['createdBy', 'reference.customer.user', 'reference.customer.address'],
      },

      getSettingsParams: {
        type: ['default'],
        key: ['default_pagination'],
      },

      getScamCategoriesParams: {
        status: 1,
        order_by_name: 'ASC',
      },
      modelForUpdating: {
        ModelId: '',
        existingData: {},
      },

      modelIdForDelete: '',
    };
  },

  watch: {
    selectedPagination(selectedPagination) {
      this.getFollowUpsParams.pagination = selectedPagination.value;
    },
    selectedService(selectedService) {
      this.getFollowUpsParams.service_id = selectedService.value;
    },

    selectedStatus(selectedStatus) {
      this.getFollowUpsParams.status = selectedStatus.value;
    },
  },

  computed: {
    ...mapGetters({
      followUps: 'appFollowUps/followUps',
      paginateLinks: 'appFollowUps/paginateLinks',
      services: 'appService/services',
    }),

    paginationList() {
      return [{
        value: this.$store.getters['appSettings/settingDefaultPagination'].value,
        name: 'Default'
      }, {
        value: 25,
        name: '25 Entries'
      }, {
        value: 50,
        name: '50 Entries'
      }, {
        value: 100,
        name: '100 Entries'
      }];
    },

    serviceList() {
      let services = this.$store.getters['appService/services'].map((service) => {
        let id = service.id;
        let name = service.name;
        return {value: id, name: name};
      });
      return [{value: '', name: 'Any'}, ...services];
    },

    statusList() {
      return [
        {
          value: '',
          name: 'Any'
        },
        {
          value: 0,
          name: 'Inactive'
        },
        {
          value: 1,
          name: 'Active'
        },
      ];
    },


  },

  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getServices: 'appService/getServices',
      getFollowUps: 'appFollowUps/getFollowUps',
      resetFollowUps: 'appFollowUps/resetFollowUps',
      putFollowUpsOnList: 'appFollowUps/putFollowUpOnList',
      deleteFollowUpOnList: 'appFollowUps/deleteFollowUpOnList',
      resetAppointments: 'appAppointments/resetAppointments',
    }),
    async vieAppointmentFullDetailsHandler(appointmentReference) {

      await this.resetAppointments();
      await this.$router.push({
        name: 'appAppointmentList',
        params: {
          appointmentReference: appointmentReference,

        }
      });

    },
    getNamesFromIds(idsString) {
      if (idsString) {
        // Split the comma-separated string into an array of IDs
        const idsArray = idsString.split(',');
        // Iterate over the array of IDs
        return idsArray.map(id => {
          // Find the corresponding name from the array of objects
          const matchedObject = this.services.find(item => item.id === parseInt(id));
          // Return the name if ID is found, otherwise return an empty string
          return matchedObject ? matchedObject.name : '';
        });
      } else {
        return '';
      }
    },

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getServiceList() {
      await this.getServices();
    },

    async getFollowUpList() {
      await this.getFollowUps(this.getFollowUpsParams).then(async (response) => {
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      })
    },

    async applyFollowUpFilter(pageNumber) {
      this.loader(true);
      this.getFollowUpsParams.page = pageNumber;
      await this.getFollowUpList();
      this.loader(false);
    },

    async scamStatusUpdating(scamStatusUpdating) {
      console.warn(scamStatusUpdating, "scamStatusUpdating")
      await this.scamStatusUpdatingOnList(scamStatusUpdating);
    },

    async scamIsFeaturedUpdating(scamIsFeaturedUpdating) {
      await this.scamIsFeaturedUpdatingOnList(scamIsFeaturedUpdating);
    },

    async scamStatusUpdatingOnList(scamStatusUpdating) {
      let dataObj = {
        id: scamStatusUpdating.modelForUpdating.modelId,
        data: {
          status: scamStatusUpdating.modelForUpdating.existingData.status === "Active" ? 0 : 1,
        }
      }

      this.putScamOnList(dataObj).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Scam status updated",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    async scamIsFeaturedUpdatingOnList(scamIsFeaturedUpdating) {
      let dataObj = {
        id: scamIsFeaturedUpdating.modelForUpdating.modelId,
        data: {
          is_featured: scamIsFeaturedUpdating.modelForUpdating.existingData.is_featured,
        }
      }

      this.putScamOnList(dataObj).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Scam is featured updated",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    async deleteSingleScamOnList(id) {
      this.deleteScamOnList(id).then(async (response) => {
        if (response.status === 200) {
          const toastObj = {
            message: "Scam Post Deleted",
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      })
    },

    async onConfirmModelDeletion(confirmModelDeletion) {
      await this.deleteSingleScamOnList(confirmModelDeletion.modelId);
    },

  },

  async mounted() {
    this.loader(true);
    await this.getSettingList();
    await this.getServiceList();
    await this.getFollowUpList();
    this.loader(false);
  },

  async created() {
    await this.resetFollowUps();
  },

}
</script>


<style scoped>

.view-edit-delete-icon {
  color: #B3C0CE;
  transition: all 0.2s;
}

.view-edit-delete-icon:hover {
  color: #5A8DEE;
}

.search-admin-input-element {
  min-height: 41px !important;
  padding: 8px 40px 8px 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}
</style>